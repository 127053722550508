import createPersistedState from "vuex-persistedstate";
import { public_url, private_url } from "@/store/axios.js";
import { uuid } from 'vue-uuid';
import config from "../../config/config.js";


const plugins = [createPersistedState()];

const state = {
    allBranches: null,
    selectedBranch: null,
    // branchId: null,
    // branches: null,
};

const mutations = {
    setAllBraches:(state, payload) => state.allBranches = payload,
    setSelectedBranch: (state, payload) => state.selectedBranch = payload,
    // setBranchId: (state, payload) => state.branchId = payload,
    // setBranches: (state, payload) => state.allBranches = payload
};

const actions = {
    viewAllBranches({}, data){
        // console.log(data, 'data:')
        return private_url.get(`/api/branch/id=${data}/`)
    },
    createBranch({}, data){
        return private_url.post('/api/branch/', data)
    },
    updateBranch({}, data){
        return private_url.put(`/api/branch/${data.branchId}/`,data)
    },
    deleteBranch({}, data){
        return private_url.delete(`/api/branch/${data}/`)
    }
};

const getters = {
    getAllBranches: (state) => state.allBranches.data,
    getSeletectedBranch: (state) => state.selectedBranch,
    getBranchId: (state) => state.selectedBranch.branchId

    // getBranches: (state) => state.branches
};

export default {
    namespaced: true, 
    state, mutations, actions, getters,
    plugins
}