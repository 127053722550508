<template>
  <div>
    <v-app>
      <topNavUrlVerification />
      <v-main class="main-background">
        <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import topNavUrlVerification from '@/components/layoutComponents/topNavUrlVerification.vue';

    export default {
        components: {
            topNavUrlVerification
        }
    }
</script>

<style lang="scss" scoped>
.main-background {
  background-color: #f5f6fa;
}
</style>