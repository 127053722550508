import createPersistedState from "vuex-persistedstate";
import { public_url, private_url } from "@/store/axios.js";

const plugins = [createPersistedState()];

const state = {
    menus: [],
    roles: [],
    menuSnackMessage: null,
    roleSnackMessage: null,
};

const mutations = {
    setMenus: (state, payload) => state.menus = payload,
    setRoles: (state, payload) => state.roles = payload,
    setMenuSnackMessage: (state, payload) => state.menuSnackMessage = payload,
    setRoleSnackMessage: (state, payload) => state.roleSnackMessage = payload
};

const actions = {
    createMenu({}, data){
        return private_url.post(`/api-menus/menu/`, data)
    },
    createRole({}, data){
        return private_url.post(`/api-roles/role/`, data)
    },
    allMenus({}){
        return private_url.get(`/api-menus/menu/`);
    },
    allRoles({}){
        return private_url.get(`api-roles/role/`)
    },
    singleRole({}, data){
        return private_url.get(`api-roles/role/${data}`)
    },
    deleteMenu({} , data){
        return private_url.delete(`/api-menus/menu/${data}/`)
    },
    deleteRole({}, data){
        return private_url.delete(`api-roles/role/${data}/`)
    },
    updateMenu({}, data){
        return private_url.put(`/api-menus/menu/${data.menuId}/`,{menu_name: data.menuName})
    },
    updateRole({}, data){
        // console.log('store obj: ', data)
        return private_url.put(`api-roles/role/${data.id}/`, {role_name: data.name, menu_permissions: data.permissions})
    },
};

const getters = {
    getMenus: (state) => state.menus,
    getRoles: (state) => state.roles,
    getMenuSnackMessage: (state) => state.menuSnackMessage,
    getRoleSnackMessage: (state) => state.roleSnackMessage,
};

export default {
    namespaced: true, 
    state, mutations, actions, getters,
    plugins
}