import createPersistedState from "vuex-persistedstate";
import { public_url, private_url } from "@/store/axios.js";
import config from "../../config/config.js";


const plugins = [createPersistedState()];

const state = {
    kybData: null,
};

const mutations = {
    setKybData: (state, payload) => state.kybData = payload
};

const actions = {
    KybSearchPerson({}, data){
        return private_url.post(`/api-kyb/search_person_fast/`, data);
    },
    kybSearchById({}, data){
        return private_url.post(`api-kyb/search_user_business/`, data);
    },
    KybSearchBusiness({}, data){
        return private_url.post(`/api-kyb/search_business_fast/`, data);
    },
    KybSearchBusinessById({}, data){
        return private_url.post(`/api-kyb/search_business_users/`, data);
    }
};

const getters = {
    getKybData: (state) => state.kybData
};

export default {
    namespaced: true, 
    state, mutations, actions, getters,
    plugins
}