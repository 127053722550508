<template>
  <div>
    <v-app-bar app color="primary" dark>
      <v-toolbar-title>
        <a href="">
          <img
            src="@/assets/logo/AppLogo.svg"
            alt=""
            width="100"
            class="mt-2"
            height="50"
          />
        </a>
      </v-toolbar-title>
      <v-divider vertical class="ml-4 mr-4"></v-divider>
      <div style="min-width:80px !important;max-width:190px !important;">
        <v-list-item>
          <v-list-item-content>
            <!-- <v-list-item-title>{{getSeletectedBusiness.businessName}}</v-list-item-title>
            <v-list-item-subtitle>{{getSeletectedBranch.branchName}}</v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  methods: {
  },
  computed: {
      ...mapGetters('business', ['getSeletectedBusiness']),
      ...mapGetters('branches', ['getSeletectedBranch'])
  }
};
</script>

<style lang="scss" scoped></style>
