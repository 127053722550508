import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/document-verification',
    name: 'document-verification',
    meta: {
      layout: "inner-layout",
      requiresAuth: true,

    },
    component: () => import(/* webpackChunkName: "about" */ '../views/WebComponents/DocumentVerification.vue')
  },
  {
    path: '/address-verification',
    name: 'address-verification',
    meta: {
      layout: "inner-layout",
      requiresAuth: true,

    },
    component: () => import(/* webpackChunkName: "about" */ '../views/WebComponents/AddressVerification.vue')
  },
  {
    path: '/verify-document/:showComponent?',
    name: 'verify-document',
    props: true,
    meta: {
      layout: "inner-layout",
      requiresAuth: true,
      title:"Documents"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/WebComponents/DocumentVerification.vue')
  },
  {
    path: '/aml-demo/',
    name: 'Aml',
    meta: {
      layout: "inner-layout",
      requiresAuth: true,
      title:"Anti Money Laundering"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/System/AMLDemo.vue')
  },
  {
    path: '/kyb-demo/:showComponent?',
    name: 'KYB',
    props: true,
    meta: {
      layout: "inner-layout",
      requiresAuth: true,
      title:"Know Your Business"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/System/KYBDemo.vue')
  },
  {
    path: '/verify-address/:showComponent?',
    name: 'verify-address',
    props: true,
    meta: {
      layout: "inner-layout",
      requiresAuth: true,
      title:"Verify Address"
    },
    component: () => import(/* webpackChunkName: "about" */  '../views/WebComponents/AddressVerification.vue')
  },
  {
    path: '/kyc-demo',
    name: 'kycDemo',
    meta: {
      layout: "inner-layout",
      requiresAuth: true,
      title:"know Your Customer"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/System/KycDemo.vue')
  },
  {
    path: '/network',
    name: 'network',
    meta: {
      layout: "inner-layout",
      requiresAuth: true,
      title:"Error"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/System/NetworkError.vue')
  },
  {
    path: '/user-info/:showComponent?',
    name: 'userInfo',
    props: true,
    meta: {
      layout: "inner-layout",
      requiresAuth: true,
      title:"User Info"
    },
    component: () => import(/* webpackChunkName: "about" */  '../views/WebComponents/UserInformation.vue')
  },
  {
    path: '/agreement',
    name: 'Agreement',
    meta: {
      layout: "inner-layout",
      requiresAuth: true,
      title:"know Your Customer"
    },
    component: () => import(/* webpackChunkName: "about" */  '../views/System/Agreement.vue')
  },
  {
    path: '/verify-url/:showComponent?',
    name: 'verifyurl',
    props: true,
    meta: {
      layout: "url-layout",
    },
    component: () => import(/* webpackChunkName: "about" */  '../views/WebComponents/UrlVerification.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    props: true,
    meta: {
      layout: "inner-layout",
      requiresAuth: true,
      title:"Dashboard"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/dashboard.vue')
  },
  {
    path: '/',
    name: 'login',
    props: true,
    meta: {
      layout: "auth-layout",
      title:"Login"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/Login.vue')
  },
  {
    path: '/register',
    name: 'register',
    props: true,
    meta: {
      layout: "auth-layout",
      title:"Register"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/Registration.vue')
  },
  {
    path: '/register-user',
    name: 'registerUser',
    props: true,
    meta: {
      layout: "inner-layout",
      title:"Manage User"
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/RegisterUser.vue')
  },
  {
    path: '/register-business',
    name: 'registerBusiness',
    props: true,
    meta: {
      layout: "business-layout",
      requiresAuth: true,
      title:"Business Setup"
    },
    component: () => import(/* webpackChunkName: "about" */ '../components/Business/registerBusiness.vue')
  },
  {
    path: "/all-branches/:showComponent?",
    name: "all-branches",
    props: true,
    meta: {
      layout: "inner-layout",
      requiresAuth: true,
      title:"All Branches"
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../components/branches/viewAllBranches.vue"),
  },
  {
    path: "/all-business/:showComponent?",
    name: "allBusiness",
    props: true,
    meta: {
      layout: "inner-layout",
      requiresAuth: true,
      title:"All Business"
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../components/Business/viewAllBusiness.vue"),
  },
  {
    path: "/Business-Branch",
    name: "BusinessBranch",
    meta: {
      layout: "business-layout",
      requiresAuth: true,
      title:"Business"
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/System/BusinessnBranch.vue"),
  },
  {
    path: "/select-branch",
    name: "selectBranch",
    meta: {
      layout: "inner-layout",
      requiresAuth: true,
      title:"Select Branch"
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/System/selectBranch.vue"),
  },
  {
    path: "/verifications/:showComponent?",
    name: "verifications",
    props: true,
    meta: {
      layout: "inner-layout",
      requiresAuth: true,
      title:"All Verifications"
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/System/Verifications.vue"),
  },
  {
    path: "/roles/:showComponent?",
    name: "Roles",
    props: true,
    meta: {
      layout: "inner-layout",
      requiresAuth: true,
      title:"All Roles"
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/RolesnPermissions/Roles.vue"),
  },
  {
    path: "/menus/:showComponent?",
    name: "Menus",
    props: true,
    meta: {
      layout: "inner-layout",
      requiresAuth: true,

    },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/RolesnPermissions/Menus.vue"),
  },
  {
    path: "/customers/:showComponent?",
    name: "customers",
    props: true,
    meta: {
      layout: "inner-layout",
      requiresAuth: true,
      title:"All Customers"
    },
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/System/Customers.vue"),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const DEFAULT_TITLE = '';
  const PROJECT_NAME = 'eyekyc';
  document.title = `${PROJECT_NAME}-${to.meta.title ? to.meta.title : DEFAULT_TITLE} `;

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    // console.log(store.getters["deo/getAccessToken"]);
    if (!store.getters["auth/getAccessToken"]) {
      router.replace('/');
    } else {
      next();
    }
  } else {
    // if (store.getters['auth/get_access_token']) {
    //     router.replace('/Dashboard')
    //   } else {
    //     next()
    //   }
  }
  next();
});

export default router
