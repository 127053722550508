<template>
  <div>
    <v-app-bar app color="white" class="elevation-1" dark>
      <v-toolbar-title>
        <a href="">
          <img
            src="@/assets/logo/AppLogo.svg"
            alt=""
            width="100"
            class="mt-2"
            height="50"
          />
        </a>
      </v-toolbar-title>
      <v-divider vertical class="ml-4 mr-4"></v-divider>
      <v-spacer></v-spacer>
      <v-btn
        @click="logoutUser"
        rounded
        text
        class="text-capitalize"
        color="grey darken-3"
        ><v-icon left>mdi-logout-variant</v-icon> Logout</v-btn
      >
    </v-app-bar>
  </div>
</template>

<script>
export default {
  methods: {
    logoutUser() {
      this.$store.commit("auth/setLoginDetails", null);
      this.$store.commit("auth/setLoggedIn", null);
      this.$store.commit("auth/setAccessToken", null);

      this.$router.push({
        name: "login",
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>