import createPersistedState from "vuex-persistedstate";
import { public_url, private_url } from "@/store/axios.js";

const plugins = [createPersistedState()];

const state = {
    customers: null,
    customerExtractedDataId: '',
};

const mutations = {
  setCustomerExtractedDataId:(state,payload)=>(state.customerExtractedDataId=payload)
};

const actions = {
    customerDetails({commit, rootGetters}){
        let branchId=rootGetters['branches/getBranchId']
        let businessId=rootGetters['business/getBusinessId']
        return private_url.get(`/api-records/customers/${businessId}/${branchId}/`)
        .then((res) => {
        // console.log(res.data, "customers:")
          return Promise.resolve(res);
        })
        .catch(error => {
          return Promise.reject(error);
        })
    },
    customerExtractedDataById({commit, rootGetters},payload){
        let branchId=rootGetters['branches/getBranchId']
        let businessId=rootGetters['business/getBusinessId']
        return private_url.post(`api-records/card-details/${businessId}/${branchId}/`,payload)
      },
};

const getters = {
    getcustomerExtractedDataId:(state)=>(state.customerExtractedDataId)
};

export default {
    namespaced: true, 
    state, mutations, actions, getters,
    plugins
}