import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import authLayout from '@/layout/auth-layout.vue';
import innerLayout from '@/layout/inner-layout.vue';
import businessLayout from '@/layout/business-layout.vue';
import urlLayout from '@/layout/url-layout.vue';

import homeButton from './components/miniComponents/homeButton'

Vue.component('homeButton',homeButton)
Vue.component('auth-layout',authLayout)
Vue.component('url-layout',urlLayout)
Vue.component('inner-layout',innerLayout)
Vue.component('business-layout',businessLayout)

Vue.config.productionTip = false
// Tell Vue to use the plugin
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
