import createPersistedState from "vuex-persistedstate";
import axios from 'axios'
import { uuid } from 'vue-uuid';
import config from "../../config/config.js";

import { public_url, private_url } from "@/store/axios.js";


const plugins = [createPersistedState()];

const state = {
    user: null,
    country: '',
    documentType: '',
    userFrontImage: '',
    userBackImage: '',
    faceImage: '',
    // imagesArray:null,
    documentVerfiedResponse: null,
    frontImagesArray: null,
    // backImagesArray:null,
    deoId: '',
    customerID: null,
    deoReceivingApi: null,
    acceptingDeoVerifiedData: null,
    browserDetail: null,
    kybAndAmlCheckboxes:null
};

const mutations = {
  setCustomerId: (state, pay) => (state.customerID = pay),
  setUserName: (state, pay) => (state.user = pay),
  setCountry: (state, pay) => (state.country = pay),
  setDocumentType: (state, pay) => (state.documentType = pay),
  setUserFrontImage: (state, pay) => (state.userFrontImage = pay),
  setUserBackImage: (state, pay) => (state.userBackImage = pay),
  setFaceImage: (state, pay) => (state.faceImage = pay),
  // setImagesArray: (state, payload) => state.imagesArray = payload,
  setDocumentVerifiedResponse: (state, pay) =>
    (state.documentVerfiedResponse = pay),
  setFrontImagesArray: (state, pay) => (state.frontImagesArray = pay),
  // setBackImagesArray: (state, pay) => state.backImagesArray = pay,
  setDeoId: (state, payload) => {
    alert(payload);
    state.deoId = payload;
  },
  setKybAndAmlCheckboxes: (state, payload) =>
    (state.kybAndAmlCheckboxes = payload),
  setDeoReceivedData: (state, payload) => (state.deoReceivingApi = payload),
  setAcceptingDeoVerifiedData: (state, payload) =>
    (state.acceptingDeoVerifiedData = payload),
  setBrowserDetail: (state, payload) => (state.browserDetail = payload),
};

const actions = {
  async documentVerification({ state, commit, rootGetters }, data) {
    // console.log("Docmument Data:",data )
    // console.log(rootGetters['branches/getSeletectedBranch'], "BranchC:")
    return private_url.post(
      `/api-ai/demo/aml=${state.kybAndAmlCheckboxes.aml}/kyb=${state.kybAndAmlCheckboxes.kyb}/`,
      data
    );
  },
  verifyAddress: ({}, payload) => {
    // console.log("my OBJ:",data )
    return private_url.post(`/api-ai/address/`, payload);
  },
  async acceptingDeoData({ commit, state }) {
    // return axios.get(`http://192.168.100.138:8000/api-ai/result/3665d71e-e99e-44ae-aee8-c458ffc97482/`).then(res  => {
    //     console.log(res)
    //     commit('setAcceptingDeoVerifiedData', res.data)
    // })
    // console.log(state.deoReceivingApi.message, "result")
    return axios.get(`${state.deoReceivingApi.message}`).then(res => {
      console.log(res);
      commit('setAcceptingDeoVerifiedData', res);
    });
  },
  apiLinksUrlCode({}, data) {
    return private_url.post(
      `/api-links/generate_link/${data.businessId}/${data.branchId}/`,
      data
    );
  },
  apiLinkData({}, data) {
    return public_url.get(`/api-links/check_link/${data}/`);
  },
};

const getters = {
    getUserName: (state) => state.user,
    getDeoId: (state) => state.deoId,
    getCustomerId: (state) => state.customerID,
    getCountry: (state) => state.country,
    getDocumentType: (state) => state.documentType,
    getUserImage: (state) => {
        let obj = {
            front: state.userFrontImage,
            back: state.userBackImage
        }
        return obj
    },
    getFaceImage: (state) => state.faceImage,
    getDocumentVerifiedResponse: (state) => state.documentVerfiedResponse.data,
    // getFrontImagesArray: (state) => state.frontImagesArray,
    // getBackImagesArray: (state) => state.backImagesArray,
    getDocumentImagesArray: (state) => {
        let obj = {
            front: state.frontImagesArray,
            back: state.backImagesArray
        }
        return obj
    },
    getAcceptingDeoVerifiedData: (state) => state.acceptingDeoVerifiedData,
    getBrowserDetail: (state) => state.browserDetail
};

export default {
    namespaced: true, 
    state, mutations, actions, getters,
    plugins
}