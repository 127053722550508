<template>
  <div>
    <v-app>
    <topNav/>
    <v-main class="main-background"> 
      <router-view> </router-view>
    </v-main>
    </v-app>
  </div>
</template>

<script>
import topNav from '@/components/layoutComponents/topNav.vue';
export default {
    components: {
        topNav
    }
};
</script>

<style lang="scss" scoped>
.main-background {
  background-color: #f5f6fa;
}
</style>