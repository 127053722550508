import createPersistedState from "vuex-persistedstate";
import { public_url, private_url } from "@/store/axios.js";
import config from "../../config/config.js";


const plugins = [createPersistedState()];

const state = {
  registerSnack: null,
  loginDetails: null,
  loggedIn: false,
  accessToken: "",
  ip: '',
};

const mutations = {
  setRegisterSnack: (state, payload) => (state.registerSnack = payload),
  setLoginDetails: (state, payload) => (state.loginDetails = payload),
  setLoggedIn:(state, payload) => (state.loggedIn = payload),
  setAccessToken: (state, payload) => (state.accessToken = payload),
  setIpAddress: (state, payload) => (state.ip = payload)
};

const actions = {
    register({commit}, data)
    {
      // console.log(data, "data:")
      return public_url.post(`api/register/`, data)
    },
    login({commit}, data){
      // console.log(data, 'data')
      return public_url.post(`api/login/`, data)
    },
    getGeoLocation({commit}){
      return private_url.get(`api-ai/return-location/`);
    }
};

const getters = {
  getRegistrationSnack: (state) => state.registerSnack,
  getAccessToken: (state) => state.accessToken,
  getLoginDetails: (state) => state.loginDetails,
  getLoggedIn: (state) => state.loggedIn,
  getIp: (state) => state.ip
};

export default {
    namespaced: true, 
    state, mutations, actions, getters,
    plugins
}