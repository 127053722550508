<template>
  <v-tooltip left>
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        icon
        class="text-capitalize"
        @click="goToDashboard()"
       
      >
        <img src="../../assets/backicon.svg" alt="" width="25">
      </v-btn>
    </template>
    <span>Cancel</span>
  </v-tooltip>
</template>

<script>

export default {
  methods: {
    goToDashboard() {
      if (this.$route.name == "verify-document") {
        this.$router.push({
          name: "verify-document",
          params: { showComponent: "documentType" },
        });
      } else if (this.$route.name == "verify-address") {
        this.$router.push({
          name: "verify-address",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>