import createPersistedState from "vuex-persistedstate";
import { public_url, private_url } from "@/store/axios.js";
import { uuid } from 'vue-uuid';
import config from "../../config/config.js";


const plugins = [createPersistedState()];

const state = {
    allBusinesses: null,
    businessId: null,
    selectedBusiness: null,
};

const mutations = {
    setAllBusinesses: (state, payload) => state.allBusinesses = payload,
    setBusinessId: (state, payload) => state.businessId = payload,
    setSelectedBusiness: (state, payload) => state.selectedBusiness = payload
};

const actions = {
    register({}, data){
        // console.log(data, "data:")
        return private_url.post('/api/business/', data)
    },
    aallBusiness({}){
        return private_url.get('/api/business/')
    },
    deleteBusiness({}, data){
        return private_url.delete(`/api/business/${data}/`)
    },
    updateBusiness({}, data){
        return private_url.put(`/api/business/${data.businessId}/`, data)
    }
};

const getters = {
    getAllBusinesses: (state) => state.allBusinesses.data,
    getBusinessId: (state) => state.selectedBusiness.businessId,
    getSeletectedBusiness: (state) => state.selectedBusiness
};

export default {
    namespaced: true, 
    state, mutations, actions, getters,
    plugins
}