import createPersistedState from "vuex-persistedstate";
import { public_url, private_url } from "@/store/axios.js";
import axios from 'axios'
import config from "../../config/config.js";


const plugins = [createPersistedState()];

const state = {
    AMLData: null,
};

const mutations = {
    setAMLData: (state, payload) => state.AMLData = payload
};

const actions = {
    AntiMoneyLaundringSearch({}, data){
        return private_url.post(`/api-aml/search/`, data);
    }
};

const getters = {
    getAmlData: (state) => state.AMLData
};

export default {
    namespaced: true, 
    state, mutations, actions, getters,
    plugins
}