import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user.js'
import auth from './modules/auth.js'
import branches from './modules/branches.js'
import business from './modules/business.js'
import customers from './modules/customers.js'
import aml from './modules/aml.js'
import kyb from './modules/kyb.js'
import RolesMenuPermissions from './modules/RolesMenuPermissions'
import verifications from './modules/verifications.js'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    user,
    auth,
    branches,
    RolesMenuPermissions,
    business,
    customers,
    verifications,
    aml,
    kyb
  }
})
