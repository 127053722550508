<template>
  <div>
    <v-app>
      <topNavInner />
      <v-main class="main-background">
        <router-view />
      </v-main>
    </v-app>
  </div>
</template>

<script>
import topNavInner from "@/components/layoutComponents/topNavInner";
export default {
  components: {
    topNavInner,
  },
};
</script>

<style lang="scss" scoped>
.main-background {
  background-color: #f5f6fa;
}
</style>