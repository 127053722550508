import createPersistedState from "vuex-persistedstate";
import { public_url, private_url } from "@/store/axios.js";
import { uuid } from 'vue-uuid';
import config from "../../config/config.js";


const plugins = [createPersistedState()];

const state = {
    verifications: null,
    singleVerification: null,
    pendingsnackbar: null,
    urlPendingsnackbar:null
};

const mutations = {
    setVerifications: (state, payload) => {state.verifications = payload},
    setSingleCustomerVerification: (state, payload) => state.singleVerification = payload,
    setPendingSnackBar: (state, payload) => state.pendingsnackbar = payload,
    setUrlPendingSnackBar: (state, payload) => state.urlPendingsnackbar = payload
};

const actions = {
    verificationDetails({commit, rootGetters})
    {
        let branchId=rootGetters['branches/getBranchId']
        let businessId=rootGetters['business/getBusinessId']
        return private_url.get(`/api-records/verifications/${businessId}/${branchId}/`)
        .then((res) => {
        // console.log(res.data, "Verification:")
        commit('setVerifications', res.data)
          return Promise.resolve(res);
        })
        .catch(error => {
          return Promise.reject(error);
        })
    },
    singleVerificationDetails({commit}, data){
        return private_url.get(`api-records/verification-detail/${data}/`).then(res =>{
            commit('setSingleCustomerVerification', res.data);
            // console.log('single response',res);
          return Promise.resolve(res);
        }).catch(e => {
            // console.log('error', e);
            return Promise.reject(e);
        })
    }

};

const getters = {
    getVerifications: (state) => state.verifications,
    getSingleCustomerVerification: (state) => state.singleVerification,
    getPendingSnackBar: (state) => state.pendingsnackbar,
    getURLPendingSnackBar: (state) => state.urlPendingsnackbar
};

export default {
    namespaced: true, 
    state, mutations, actions, getters,
    plugins
}