<template>
  <div>
    <v-navigation-drawer v-model="drawer" app clipped>
      <v-list-item>
        <v-list-item-avatar color="primary" rounded>
          <v-avatar size="40" rounded color="primary white--text">
            {{ getSeletectedBusiness.businessName.charAt(0).toUpperCase() }}
          </v-avatar>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="subtitle-1 font-weight-medium">
            {{ getSeletectedBusiness.businessName }}
          </v-list-item-title>
          <v-list-item-subtitle class="subtitle-2">
            {{ getSeletectedBranch.branchName }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-list-item
          link
          
          active-class="nav-btn-active"
          :to="{ name: 'dashboard' }"
        >
          <v-list-item-icon>
            <v-icon left>mdi-desktop-mac</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          active-class="nav-btn-active"
          link
          :to="{ name: 'customers' }"
        >
          <v-list-item-icon>
            <v-icon left>mdi-contacts-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Customers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          active-class="nav-btn-active"
          link
          :to="{ name: 'verifications' }"
        >
          <v-list-item-icon>
            <v-icon left>mdi-file-document</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Verifications</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-subheader>Know Your Customer Demo</v-subheader>
        <v-list-item
          active-class="nav-btn-active"
          link
          :to="{ name: 'Agreement' }"
        >
          <v-list-item-icon>
            <v-icon left class="mt-2">mdi-face-recognition</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>KYC</v-list-item-title>
            <v-list-item-subtitle class="nav-sub-btn-active"
              >know Your Customer</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item active-class="nav-btn-active" link :to="{ name: 'Aml' }">
          <v-list-item-icon>
            <v-icon left class="mt-2">mdi-folder-information-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>AML</v-list-item-title>
            <v-list-item-subtitle class="nav-sub-btn-active"
              >Anti-Money Laundering</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item active-class="nav-btn-active" link :to="{ name: 'KYB' }">
          <v-list-item-icon>
            <v-icon left class="mt-2">mdi-briefcase-account-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>KYB</v-list-item-title>
            <v-list-item-subtitle class="nav-sub-btn-active"
              >Know Your Business</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list dense nav>
        <v-subheader>System Info</v-subheader>
        <v-list-item
          active-class="nav-btn-active"
          :to="{ name: 'allBusiness' }"
        >
          <v-list-item-icon
            ><v-icon left>mdi-briefcase-account-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Business </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          active-class="nav-btn-active"
          :to="{ name: 'all-branches' }"
        >
          <v-list-item-icon
            ><v-icon left>mdi-home-city-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Branches </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- <v-list-item active-class="nav-btn-active" :to="{ name: 'Roles' }">
          <v-list-item-icon
            ><v-icon left>mdi-checkbox-marked-circle-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> Roles</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
      </v-list>
      <template v-slot:append>
        <v-divider></v-divider>
        <div class="pt-0 pb-0">
          <v-menu offset-y transition="slide-x-transition">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item dense v-bind="attrs" class="grey lighten-5" v-on="on">
                <v-list-item-avatar color="primary" size="36" rounded>
                  <v-avatar rounded size="36" color="primary white--text" class="title">
                    {{ getLoginDetails.username.charAt(0) }}
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-1 font-weight-medium">
                    {{ getLoginDetails.username }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="caption">
                    {{ getLoginDetails.is_superuser ? "Super User" : "User" }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-card>
              <div class="d-flex justify-space-between ma-0 pa-0">
                <v-list-item class="text-center mt-n2">
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-avatar
                        size="60"
                        color="primary white--text"
                        class="title mt-6"
                      >
                        {{ getLoginDetails.username.charAt(0).toUpperCase() }}
                      </v-avatar>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      class="subtitle-1 font-weight-medium mt-3"
                      >{{ getLoginDetails.username }}</v-list-item-subtitle
                    >
                    <!-- Role -->
                    <v-list-item-subtitle
                      class="subtitle-2 font-weight-medium mt-1"
                      >{{
                        getLoginDetails.is_superuser ? "Super User" : "User"
                      }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
              </div>
              <v-list dense>
                <v-list-item
                  v-if="getLoginDetails.is_superuser"
                  @click="routeToRegisterUser"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-account-circle-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Manage User</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logoutUser">
                  <v-list-item-icon>
                    <v-icon>mdi-logout-variant</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> Logout</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="white" class="elevation-1" clipped-left dark>
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
        color="grey darken-3"
        class="ma-0"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <a href="#">
          <img
            src="@/assets/logo/AppLogo.svg"
            alt=""
            width="125"
            class="mt-2"
            height="50"
          />
        </a>
      </v-toolbar-title>
      <v-spacer></v-spacer>
       <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
      <v-btn
        text
        @click.stop="drawerRight = !drawerRight"
        icon
        color="grey darken-3"
        class="ma-0"
        v-bind="attrs"
          v-on="on"
      >
        <v-icon> mdi-camera-switch-outline</v-icon>
      </v-btn>
      </template>
      <span>switch business or branches</span>
    </v-tooltip>
      <!-- <v-menu
        offset-y
        max-width="280"
        min-width="280"
        transition="scroll-x-reverse-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-avatar
              size="40"
              color="primary white--text"
              class="font-weight-bold"
            >
              {{ getLoginDetails.username.charAt(0) }}
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <div class="d-flex justify-space-between ma-0 pa-0">
            <v-list-item two-line class="text-center mt-n2">
              <v-list-item-content>
                <v-list-item-title>
                  <v-avatar
                    size="70"
                    color="primary white--text"
                    class="title mt-6"
                  >
                    {{ getLoginDetails.username.charAt(0).toUpperCase() }}
                  </v-avatar>
                </v-list-item-title>
                <v-list-item-subtitle
                  class="subtitle-1 font-weight-medium mt-3"
                  >{{ getLoginDetails.username }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  class="subtitle-1 font-weight-medium mt-3"
                  >{{
                    getLoginDetails.is_superuser ? "Super User" : "User"
                  }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </div>
          <v-list>
            <v-list-item
              v-if="getLoginDetails.is_superuser"
              @click="routeToRegisterUser"
            >
              <v-list-item-icon>
                <v-icon>mdi-account-circle-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Manage User</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logoutUser">
              <v-list-item-icon>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-title> Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu> -->
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawerRight"
      app
      right
      temporary
      width="320"
      flat
      hide-overlay
      class="elevation-0"
    >
      <template v-slot:prepend top>
        <div>
          <v-card tile flat>
            <v-card-title
              ><v-icon left>mdi-apps</v-icon>
              {{ drawerHeaderText }}
            </v-card-title>
          </v-card>
          <v-divider></v-divider>
        </div>
      </template>
      <v-list dense v-if="!drawerSwitch">
        <v-subheader class="subtitle-2 ml-2">Select Branch</v-subheader>
        <v-list-item
          v-for="(branch, i) in getAllBranches"
          :key="i"
          @click="selectBranch(branch)"
          ><v-list-item-avatar class="primary" size="32">
            <v-icon class="white--text" small>mdi-home-city</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ branch.branchname }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list dense v-if="drawerSwitch">
        <v-subheader class="subtitle-2 ml-2">Select Business</v-subheader>
        <v-list-item
          v-for="(business, i) in getAllBusinesses"
          :key="i"
          @click="selectBusiness(business)"
          ><v-list-item-avatar class="primary" size="32">
            <v-icon class="white--text" small>mdi-home-city</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{
              business.thebusinessname
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list dense>
          <v-divider></v-divider>
          <v-list-item @click="showBusiness">
            <v-list-item-avatar class="primary" size="32">
              <v-icon class="white--text title" small
                >mdi-camera-switch-outline</v-icon
              >
            </v-list-item-avatar>
            <v-list-item-content class="font-weight-medium">
              Switch Business
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon color="grey lighten-1" v-on="on"
                    >mdi-information</v-icon
                  >
                </template>
                <span>Switch To Another Business</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      drawer: null,
      drawerRight: null,
      drawerSwitch: false,
      drawerHeaderText: "All Branches",
      selectedBranch: {
        branchName: "",
        branchId: "",
      },
      selectedBusiness: {
        businessName: "",
        businessId: "",
      },
    };
  },
  computed: {
    ...mapGetters("auth", ["getLoggedIn", "getLoginDetails"]),
    ...mapGetters("branches", ["getAllBranches", "getSeletectedBranch"]),
    ...mapGetters("business", ["getSeletectedBusiness", "getAllBusinesses"]),
  },
  // created(){
  //     this.drawerSwitch= false;
  // },
  methods: {
    routeToRegisterUser() {
      this.$router.push({
        name: "registerUser",
      });
    },
    logoutUser() {
      this.$store.commit("auth/setLoginDetails", null);
      this.$store.commit("auth/setLoggedIn", null);
      this.$store.commit("auth/setAccessToken", null);
      this.$router.push({
        name: "login",
      });
    },
    createBranch() {
      this.$router.push({
        name: "all-branches",
        params: { showComponent: "add-branch" },
      });
    },
    selectBranch(branch) {
      this.selectedBranch.branchName = branch.branchname;
      this.selectedBranch.branchId = branch.branchid;
      // console.log('branch::', this.selectedBranch)
      this.$store.commit("branches/setSelectedBranch", this.selectedBranch);
      this.$router.push({
        name: "dashboard",
      });
    },
    showBusiness() {
      this.drawerHeaderText = "All Business";
      this.drawerSwitch = true;
    },
    async selectBusiness(business) {
      // console.log(business)
      this.selectedBusiness.businessId = business.thebusinessid;
      this.selectedBusiness.businessName = business.thebusinessname;
      await this.$store.commit(
        "business/setSelectedBusiness",
        this.selectedBusiness
      );
      this.drawerHeaderText = "All Branches";
      this.drawerSwitch = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-topNav {
  // background-color: white !important;
  // color: #FF5722;
  // opacity:0.18 !important;
}
.nav-btn-active {
  background-color: #4464e4;
  color: white;
   box-shadow: 0px 7px 8px -4px rgb(90 118 231 / 20%), 0px 12px 17px 2px rgb(90 118 231 / 14%), 0px 5px 22px 4px rgb(90 118 231 / 12%) !important;
}

.nav-btn-active .nav-sub-btn-active {
  color: white;
}
</style>
